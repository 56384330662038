import React, { useState, useRef } from "react";
import { workData } from "../data";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Navigation module styles
import { Navigation } from "swiper/modules";

export const LatestWork = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <section className="bg-white">
      <div className="container mx-auto py-32 px-5 md:px-0">
        <p className="font-semibold text-primary">OUR FORTFOLIO</p>
        <div className="flex justify-between items-end flex-col md:flex-row">
          <h2>
            Showcasing Our. <br/> Latest
            Creative Masterpieces
          </h2>
          <div className="flex space-x-4 justify-start md:justify-end px-0 md:px-20">
            <div
              className="cursor-pointer bg-black px-2 text-white hover:scale-110 rounded duration-150 transform hover:-translate-x-2"
              ref={prevRef}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
            </div>

            <div
              ref={nextRef}
              className="cursor-pointer hover:scale-110 rounded bg-black px-2 text-white  transform hover:translate-x-2 duration-150"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* work grid */}
        <Swiper
          slidesPerView={1}
          breakpoints={{
            640: { // sm screens
              slidesPerView: 1,
              spaceBetween: 15,
            },
            768: { // md screens
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: { // lg screens
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
          spaceBetween={40}
          grabCursor={true}
          loop={true}
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          className="mt-20 "
        >
          {workData &&
            workData.map((item, index) => (
              <SwiperSlide key={index} className="group rounded-xl  ">
                <div className="rounded-lg relative overflow-hidden ">
                  <div className="absolute">
                    <div className="relative bg-white px-6  shadow-lg rounded-br-[1.5rem] py-4 space-x-3">
                      {item.tags &&
                        item.tags.map((tag) => (
                          <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white">
                            {tag}
                          </span>
                        ))}
                      {/* bottom svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=" fill-white h-10 w-10 absolute -left-3  -bottom-10"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {/* right svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="md:w-8 md:h-8 absolute md:-right-8 h-4 w-4 -right-4  top-0 fill-white"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="absolute bottom-0 right-0 duration-150 transform translate-y-full group-hover:translate-y-0">
                    <div className="p-4 rounded-tl-2xl bg-white shadow-xl  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-8  "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-6 w-6 bottom-0 -left-6 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-6 w-6 -top-6 right-0 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>

                    </div>
               
                  </div>
                  <img
                    src={item.image}
                    className="w-full rounded-[1.5rem]"
                    alt=""
                  />
                </div>
                <div className="px-4 py-3">
                  <p className="text-2xl font-light mt-3 group-hover:underline">
                    {item.title}
                  </p>
                  <p className="text-semibold text-primary">{item.client}</p>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
         
      </div>
    </section>
  );
};
