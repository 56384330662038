import React, { useRef } from "react";
import { Navbar } from "../comp/Navbar";
import { Hero } from "../comp/Hero";
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/navigation"; // Navigation module styles

// Import the Navigation module correctly
import { Navigation } from "swiper/modules";
import { Services } from "../comp/Services";
import { Companies } from "../comp/Companies";
import { LatestWork } from "../comp/LatestWork";
import { Blogs } from "../comp/Blogs";

export const Home = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="bg-white">
     <Helmet>
     <title>Creative Graphic & Web Design Agency | Graphwizards, Patiala</title>
        <meta name="description" content="Graphwizards is a creative design agency based in Patiala, Punjab. We offer stunning graphic and web design services to elevate your brand." />
        <meta name="keywords" content="Graphwizards, graphic design, web design, design agency, Patiala, creative agency, Punjab" />
        <link rel="canonical" href="https://graphwizards.in/" />
      </Helmet>
      
      <Hero />

      <div className="bg-white pt-32 ">
        <div className=" ">
          <div className="bg-black relative  rounded-tl-[1.5rem] md:rounded-tl-[3rem]  ">
            

            <svg
              id="Isolation_Mode"
              xmlns="http://www.w3.org/2000/svg"
              className=" md:h-16 h-8    absolute md:-top-16 -top-8 right-0 fill-black"
              viewBox="0 0 86.57 86.18"
            >
              <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
            </svg>

            <div className="container mx-auto px-5 md:px-0">
              {/* ///[slider container] */}
              <div className="flex md:flex-row flex-col-reverse">
                <div className="w-full md:w-4/12">
                  <div className="pr-0 md:pr-8 ">
                    <h2 className="text-white -mt-20 md:mt-10 ">
                      Showcasing Creativity and Innovation in Every Design
                    </h2>
                    <p className="text-white/40 font-light mt-3 md:mt-6">
                      Discover our recent projects, featuring dynamic websites
                      and unique brand identities, all crafted with a focus on
                      impact and results.
                    </p>
                  </div>
                </div>

                <div className="md:w-8/12 w-full">
                  <div className="md:rounded-[1.5rem] rounded-2xl overflow-hidden transform -translate-y-32 bg-slate-50 relative">
                    <div className="absolute   z-10">
                      <div className=" md:py-3 py-2 bg-white  md:px-12 px-8 rounded-br-lg md:rounded-br-[1.5rem] shadow-lg relative">
                        {/* corners */}

                        {/* bottom svg */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="md:w-8 md:h-8 w-4 -4 absolute md:-bottom-8 -bottom-4  left-0 fill-white"
                          viewBox="0 0 33.131 33.234"
                        >
                          <path
                            id="Subtraction_1"
                            data-name="Subtraction 1"
                            d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                            transform="translate(0.001)"
                          />
                        </svg>

                        {/* right svg */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="md:w-8 md:h-8 absolute md:-right-8 h-4 w-4 -right-4  top-0 fill-white"
                          viewBox="0 0 33.131 33.234"
                        >
                          <path
                            id="Subtraction_1"
                            data-name="Subtraction 1"
                            d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                            transform="translate(0.001)"
                          />
                        </svg>

                        <div className="flex space-x-4  ">
                          <div
                            className="cursor-pointer hover:scale-150 duration-150"
                            ref={prevRef}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="md:size-8 size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                              />
                            </svg>
                          </div>

                          <div
                            ref={nextRef}
                            className="cursor-pointer hover:scale-150 duration-150"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="md:size-8 size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Swiper
                      grabCursor={true}
                      modules={[Navigation]}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      spaceBetween={50}
                      slidesPerView={1}
                      loop={true} // Enable loop if needed
                    >
                      {/* Swiper slides */}
                      <SwiperSlide>
                        <img
                          src="https://images.adsttc.com/media/images/6167/d2bc/7059/3a01/64b1/8539/slideshow/eram-competition-by-conceptdesigncompany-002.jpg?1634194122"
                          className="w-full"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>Slide 2</SwiperSlide>
                      <SwiperSlide>Slide 3</SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>

              <Services />
            </div>
          </div>
        </div>
      </div>

      {/* ///[services] */}

      <LatestWork />
      {/* <div className="py-10 overflow-hidden  ">
     <div className="transform scale-105">
     <marquee  behavior="scroll" direction="right" scrollamount="10" scrolldelay="0" loop="infinite" className="text-[10rem] transform rotate-  p-0 ">Let's start work together. Let's start work together. Let's start work together. </marquee>
      <marquee  behavior="scroll" direction="left" scrollamount="10" loop="infinite" className="text-[10rem]  -mt-10 transform -rotate-  p-0">Let's start work together. Let's start work together. Let's start work together. </marquee>

     </div>
      </div> */}
      <Blogs/>

      <Companies />

  
    </div>
  );
};
