import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { Footer } from "../comp/Footer";
import { workData } from "../data";
import { cat } from "../data";
import { Link } from "react-router-dom";

export const Work = () => {
  const [catSelect, setCatSelect] = useState(0);
  return (
    <>
      <Helmet>
        <title>Our Work | Portfolio of Graphwizards, Patiala</title>
        <meta
          name="description"
          content="Explore the creative work by Graphwizards. Check out our portfolio of graphic and web design projects from various industries."
        />
        <meta
          name="keywords"
          content="Graphwizards portfolio, web design projects, graphic design portfolio, Patiala design agency, Punjab creative work"
        />
        <link rel="canonical" href="https://graphwizards.in/portfolio" />
      </Helmet>

      <div className="bg-white">
        <div className="mx-auto container py-20 md:py-32 px-5 md:px-0">
          <p className="text-primary font-semibold">Our Work</p>
          <ul className="flex flex-wrap  space-y-5 md:space-y-6 items-end text-3xl md:text-5xl font-light text-black/30">
            {cat &&
              cat.map((item, index) => (
                <li
                  className={`${
                    index === catSelect ? "text-black" : "hover:text-black"
                  } mr-6  md:mr-8 cursor-pointer`}
                  key={index}
                  onClick={() => setCatSelect(index)} // Optionally, set the selected category on click
                >
                  {item} <span className="text-sm">12</span>
                </li>
              ))}
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-32  container px-5 md:px-0 mx-auto gap-10">
       

          
          {workData &&
            workData.map((item, index) => (
              <Link to={'project/'+item.title}>
              <div key={index} className="group rounded-xl  ">
                <div className="rounded-lg relative overflow-hidden ">
                  <div className="absolute">
                    <div className="relative bg-white px-6  shadow-lg rounded-br-[1.5rem] py-4 space-x-3 transform -translate-y-full group-hover:-translate-y-0  duration-150">
                      {/* bottom svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=" fill-white h-6 w-6 absolute left-0  -bottom-6"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {/* right svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="md:w-6 md:h-6 absolute md:-right-6 h-4 w-4 -right-4  top-0 fill-white"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {item.tags &&
                        item.tags.map((tag) => (
                          <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white text-sm">
                            {tag}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="absolute bottom-0 right-0 duration-150 transform translate-y-full group-hover:translate-y-0">
                    <div className="p-3 rounded-tl-xl bg-white shadow-xl  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-6 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 bottom-0 -left-4 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 -top-4 right-0 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>
                    </div>
                  </div>
                  <img
                    src={item.image}
                    className="w-full rounded-[1rem]"
                    alt=""
                  />
                </div>
                <div className="px-4 py-3">
                  <p className="text-xl font-light mt-3 group-hover:underline">
                    {item.title}
                  </p>
                  <p className="text-semibold text-lg text-primary">
                    {item.client}
                  </p>
                </div>
              </div>
              </Link>
            ))}

            
        </div>
      </div>

 

   
    </>
  );
};
