import React ,{useRef} from "react";

export const Blogs = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
  return (
    <section className="py-28">
      <div className="container mx-auto flex">
        <div className="w-3/12 border-r py-10">
          <p className="font-semibold text-primary">OUR FORTFOLIO</p>
          <p className="text-5xl font-extralight mt-2">
          The latest from our  design studio
          </p>

          <div className="flex space-x-4 mt-10">
            <div
              className="cursor-pointer   text-black hover:scale-110 rounded duration-150 transform hover:-translate-x-2"
              ref={prevRef}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
            </div>

            <div
              ref={nextRef}
              className="cursor-pointer hover:scale-110   text-black  transform hover:translate-x-2 duration-150"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
