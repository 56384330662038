import React from 'react'
import logo from "./../img/logo.svg";
import { NavLink , Link } from 'react-router-dom';

export const Navbar = () => {
  return (
    <div className=' bg-white z-10 border-t-4 border-primary md:border-t-0'>
      <div className='container mx-auto flex items-center justify-between px-5 md:px-0'>
      <img src={logo} className='h-20' alt="Logo" />

      <ul className='md:flex space-x-5 font-light text-2xl text-slate-500 hidden'>
        <li >
        <NavLink
      to="/"
      className={({ isActive }) =>
        `border-b-2 h-full px-3 pb-2 ${
          isActive ? 'border-b-blue-600' : 'border-transparent  hover:border-b-slate-200'
        }`
      }
    >
      Home
    </NavLink>
        </li>

        <li >
        <NavLink
      to="/portfolio"
      className={({ isActive }) =>
        `border-b-2 h-full px-3 pb-2 ${
          isActive ? 'border-b-blue-600 text-slate-800' : 'border-transparent  hover:border-b-slate-200'
        }`
      }
    >
      Portfolio
    </NavLink>
        </li>

        <li >
        <NavLink
      to="/services"
      className={({ isActive }) =>
        `border-b-2 h-full px-3 pb-2 ${
          isActive ? 'border-b-blue-600' : 'border-transparent  hover:border-b-slate-200'
        }`
      }
    >
      Services
    </NavLink>
        </li>

        <li >
        <NavLink
      to="/blogs"
      className={({ isActive }) =>
        `border-b-2 h-full px-3 pb-2 ${
          isActive ? 'border-b-blue-600' : 'border-transparent  hover:border-b-slate-200'
        }`
      }
    >
      Blogs
    </NavLink>
        </li>

        <li >
        <NavLink
      to="/contact"
      className={({ isActive }) =>
        `border-b-2 h-full px-3 pb-2 ${
          isActive ? 'border-b-blue-600' : 'border-transparent  hover:border-b-slate-200'
        }`
      }
    >
      Contact
    </NavLink>
        </li>
        

      </ul>

      <Link to={'/start-project'} className='btn   btn-neutral rounded-full hidden md:inline-flex'>   <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>Let's Start</Link>
      </div>
    </div>
  )
}
