import React from 'react'

import { Helmet } from 'react-helmet';

export const Contact = () => {
  return (
    <>
       <Helmet>
        <title>Contact Us | Graphwizards, Patiala | Web & Graphic Design Services</title>
        <meta name="description" content="Get in touch with Graphwizards for web and graphic design services. Contact our creative agency in Patiala, Punjab." />
        <meta name="keywords" content="contact, Graphwizards, design agency contact, Patiala, Punjab, creative solutions, web design, graphic design" />
        <link rel="canonical" href="https://graphwizards.in/contact" />
      </Helmet>
       
    </>
  )
}
