import React from "react";

import { Helmet } from "react-helmet";

export const Services = () => {
  const services = [
    {
      id: 1,
      name: "Design",
      description:
        "Brand designers and web designers in-house crafting visuals to match your brand values.",
      list: ["Brand Identity", "Web Design", "eCommerce", "Graphic Design"],
    },
    {
      id: 1,
      name: "Develop",
      description:
        "Web development to the highest standards, and matching the latest industry requirements.",
      list: ["Web Development", "Craft CMS", "Craft Commerce", "eCommerce"],
    },
    {
      id: 1,
      name: "Support",
      description:
        "Lean on our in-house team to support with your design, development and seo needs.",
      list: ["SEO", "Web Hosting", "Content Writing", "Marketing"],
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Services | Web & Graphic Design Solutions | Graphwizards, Patiala
        </title>
        <meta
          name="description"
          content="Graphwizards offers professional web design, graphic design, branding, and digital marketing services. Empower your business with our creative solutions."
        />
        <meta
          name="keywords"
          content="design services, web design, graphic design, branding, digital marketing, Graphwizards, Patiala, Punjab"
        />
        <link rel="canonical" href="https://graphwizards.in/services" />
      </Helmet>
      <div className="bg-white">
        <div className="container mx-auto px-5 md:px-0 md:pt-32 pt-20 pb-20">
          <div className="py-20 grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 ">
            <div>
              <p className="text-md font-light text-primary">Services</p>
              <p className="md:text-5xl  text-4xl md:w-2/3 ">
                Graphwizards is a graphic and web design studio based in Patiala
              </p>
            </div>

            <div className=" flex md:justify-end">
              <p className="md:w-2/3 text-md font-light text-black/60">
                Our in-house team at Graphwizards is uniquely skilled in both
                graphic and web design, making us stand out in the industry.
                We’ve built a team of talented professionals, handpicked through
                our co-founders’ networks, allowing us to offer a broad range of
                services. Whether you’re a start-up needing a brand from
                scratch, or an established business looking to revamp or elevate
                your brand, we’ve got you covered.
              </p>
            </div>
          </div>
          <hr />

          <div className="bg-black rounded-2xl relative">
            {/* absolute */}
            <div className="absolute -top-1 left-0 w-full">
              <div className="relative bg-white  rounded-br-2xl h-14   md:w-1/4 w-1/2">
                {/* bottom svg */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:w-8 md:h-8 w-4 -4 absolute md:-bottom-8 -bottom-4  left-0 fill-white"
                  viewBox="0 0 33.131 33.234"
                >
                  <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                    transform="translate(0.001)"
                  />
                </svg>

                {/* right svg */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:w-8 md:h-8 absolute md:-right-8 h-4 w-4 -right-4  top-0 fill-white"
                  viewBox="0 0 33.131 33.234"
                >
                  <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                    transform="translate(0.001)"
                  />
                </svg>
              </div>
            </div>

            {/* ?end of absolute div */}
            <div className=" pb-20 pt-24 md:px-20 px-10 mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-5 gap-10">
                {services &&
                  services.map((service) => (
                    <div className="px-5">
                      <p className="text-7xl text-white m-0">{service.name}</p>
                      <div className=" mt-5">
                        <div className="">
                          <p className="m-0 text-white/50 font-extralight text-xl ">
                            {service.description}
                          </p>
                        </div>
                        <div className=" mt-4">
                          <ul className="text-2xl text-white/80 space-y-3  font-light">
                            {service.list.map((item, index) => (
                              <li
                                key={index}
                                className="py-4 border-b group hover:text-white hover:border-b-white border-b-white/20"
                              >
                                {" "}
                                <span className="mr-3 text-sm">
                                  0{index + 1}
                                </span>{" "}
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
