import React from "react";
import banner from "./../img/banner.jpg";

export const Hero = () => {
  return (
    <div className=" bg-white px-5 md:px-0">
      <div className="container mx-auto  ">
        <div className="md:grid md:grid-cols-2  md:min-h-[650px] gap-12">
          <div className="flex md:flex-wrap py-20 md:py-0 md:content-center h-full ">
            <div className=" ">
              <p className="mb-2 md:text-3xl text-lg font-light   ">
                Transform Your Vision into{" "}
              </p>
              <p className="md:text-8xl text-5xl font-light   ">
                Stunning Digital Experiences
              </p>
              <p className="text-neutral-400 mt-4 md:text-lg ">
                Ready to bring your ideas to life? Let’s collaborate to create
                exceptional designs that captivate and inspire. Start your
                project with us today and watch your vision turn into reality!
              </p>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  );
};
