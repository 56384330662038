import React from "react";
import { Footer } from "./Footer";

export const Companies = () => {
  return (
    <section className="relative  bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.100),white)]">
      <div className="container mx-auto pt-32 pb-24 px-5 md:px-0">
        <div>
          <p className="font-semibold text-primary">OUR CLIENTS</p>
          <div className=" grid md:grid-cols-2 gap-10  grid-cols-1">
            <div>
              <p className="text-5xl font-extralight   mt-2">
                Trusted by Visionary <br /> Brands and Businesses
              </p>
              {/* <p className="mt-3 text-xl font-extralight text-neutral-500  ">
          Our clients span diverse industries, all sharing a commitment to
          innovation and excellence. We take pride in delivering tailored
          graphic and web design solutions that drive their success.”
        </p> */}
            </div>
            <div className="  grid md:grid-cols-3 grid-cols-2   gap-x-5 gap-y-8  md:gap-x-8 md:gap-y-12">
              <img
                alt="Transistor"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                className=" md:max-h-12 max-h-10 w-full object-contain  "
              />
              <img
                alt="Reform"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                className=" md:max-h-12 max-h-10 w-full object-contain  "
              />
              <img
                alt="Tuple"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                className=" md:max-h-12 max-h-10 w-full object-contain  "
              />
              <img
                alt="SavvyCal"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                className=" md:max-h-12 max-h-10 w-full object-contain  "
              />
              <img
                alt="Statamic"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                className="   md:max-h-12 max-h-10 w-full object-contain  "
              />
              <img
                alt="Statamic"
                src="https://tailwindui.com/img/logos/laravel-logo-gray-900.svg"
                className="    md:max-h-12 max-h-10 w-full object-contain  "
              />
            </div>
          </div>
        </div>

   
      </div>
    </section>
  );
};
