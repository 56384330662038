import React from "react";

export const Project = () => {
  const tags = ["Brand", "Website", "Marketing"];
  return (
    <>
      <div className="bg-white">
        <div className="container mx-auto  pt-10 pb-5 md:pt-32 md:pb-10 px-5 md:px-0">
          <div className="relative">
            <div className="hidden md:block absolute top-0 left-0">
              <div className="bg-white pr-4 pb-10 md:w-2/3 rounded-br-[2rem] relative">
                {/* bottom svg */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:w-8 md:h-8 w-4 -4 absolute md:-bottom-8 -bottom-4  left-0 fill-white"
                  viewBox="0 0 33.131 33.234"
                >
                  <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                    transform="translate(0.001)"
                  />
                </svg>

                {/* right svg */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:w-8 md:h-8 absolute md:-right-8 h-4 w-4 -right-4  top-0 fill-white"
                  viewBox="0 0 33.131 33.234"
                >
                  <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                    transform="translate(0.001)"
                  />
                </svg>

                <p className="md:text-7xl text-lg  font-extralight text-neutral">
                  Rebranding luxury dance facilities
                </p>
                <div className="mt-5 space-x-4  ">
                  {tags.map((tag) => (
                    <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <img
              className="w-full rounded-xl md:rounded-[2rem] hidden md:block"
              src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/studio25-header.jpg?w=1127&h=633.9375&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1632321217&s=81f0c5e85d3134750e17de59c40d45c7"
              alt=""
            />

 
              <img
                className=" rounded-xl md:rounded-2xl block md:hidden"
                src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/studio25-tee1.jpg?w=935&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631805208&s=14b29cc27feb374ef7611e9b9ffd5853"
                alt=""
              />
        
          </div>

          <div className="mx-auto max-w-7xl">
            {/* name */}
          <div className="mt-10 block md:hidden">
            <p className=" text-5xl  font-extralight text-neutral">
              Rebranding luxury dance facilities
            </p>
            <div className="mt-4 space-x-4  ">
              {tags.map((tag) => (
                <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white">
                  {tag}
                </span>
              ))}
            </div>
          </div>

          {/* description */}
          <div className="py-10 md:flex md:gap-4">
            <p className="font-extralight md:w-6/12 sm:w-full w-full text-lg">
              Studio 25 is a multipurpose dance studio in the heart of
              Manchester, cultivating modern and traditional dance. We were
              appointed by Studio 25 to drive their growth through a total brand
              overhaul, including a re-brand and new site design & build to
              position them as market leaders in the industry.
            </p>
            <div className="w-full md:w-6/12 sm:w-full   flex  flex-row  md:justify-end   justify-between mt-8 md:mt-0 space-x-5">
              <div className="px-4 ">
                <p className="text-sm  font-extralight text-neutral">Client</p>
                <p className="text-lg">Studio25</p>
              </div>
              <div className="px-4 ">
                <p className="text-sm  font-extralight text-neutral">
                  Industry
                </p>
                <p className="text-lg">Fitness</p>
              </div>
              <div className="px-4">
                <p className="text-sm  font-extralight text-neutral">
                  Duration
                </p>
                <p className="text-lg">12 Week</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
            <div>
              <img
                className=" rounded-xl md:rounded-2xl"
                src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/studio25-tee1.jpg?w=935&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631805208&s=14b29cc27feb374ef7611e9b9ffd5853"
                alt=""
              />
            </div>

            <div>
              <img
                className=" rounded-xl md:rounded-2xl"
                src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/studio25-logo.jpg?w=935&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631805355&s=44b81f1bf91257f92696c20a44583493"
                alt=""
              />
            </div>

            <div className="md:col-span-2">
              <img
                className=" rounded-xl md:rounded-2xl w-full"
                src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/billboard.jpg?w=1920&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631882622&s=37997fc9e64d8d1762ba760bb2d4e2a6"
                alt=""
              />
            </div>
          </div>

          {/* diff */}
          <div>
            <div className="diff aspect-[16/9] rounded-xl md:rounded-2xl overflow-hidden mt-10 bg-slate-50  ">
              <div className="diff-item-1 ">
              <img className=" " src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/window.jpg?w=2500&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631804716&s=4b48cde02af80f2b53435103198dd145" alt="" />
              </div>
              <div className="diff-item-2">
               <img className=" " src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/bottle.jpg?w=2500&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631804709&s=a31b8cd940d7f54d9116b8fe9a57b15a" alt="" />
              </div>
              <div className="diff-resizer"></div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};
